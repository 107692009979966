
import {
  useState,
} from 'react'
import Button from '@mui/material/Button'
import { DropzoneDialog } from 'react-mui-dropzone'

export default function DropZoneDialogExp({
  title,
  upload,
} : {
  title : string,
  upload : (files : File[]) => void,
}) {

  const [open, setOpen] = useState(false);

  //         acceptedFiles={['*/*']}
  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Upload
      </Button>

      <DropzoneDialog
        dialogTitle={title}
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={3 * Math.pow(1024, 3)}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files : File[]) => {
          console.log('Files:', files);
          upload(files)
          setOpen(false);
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
    </div>
  )
}
