
import { useAccessToken } from 'utils/hooks'

import { useSetAuth } from '../features/auth/slice'

export default function Auth() {
  const token = useAccessToken()
  const setAuth = useSetAuth()
  if (token !== null) setAuth({
    token: token.accessToken,
    username: '',
    email: '',
  })
  return <></>
}

