import {
  useState,
  useReducer,
  // useEffect,
  // useLayoutEffect,
  useMemo,
  ChangeEvent,
} from 'react'
import {
  useNavigate,
} from 'react-router-dom'

import Box from '@mui/material/Box'
//import Container from '@mui/material/Container'

// import Typography from '@mui/material/Typography'
//import Divider from '@mui/material/Divider'

import Button from '@mui/material/Button'
//import IconButton from '@mui/material/IconButton'
//import AddIcon from '@mui/icons-material/Add';

import TextField from '@mui/material/TextField'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import { DateTime } from 'luxon'

import {
  // useCheckRes,
  useDebounce,
  useCheckQueryRes,
  useCheckRes,
} from 'utils/hooks'

import ToggleButton, {
  genReducer,
} from 'Components/ToggleButton'

import dataApi from 'services/data'

import { printDate } from 'utils'

function alertDate(d : string) {
  const dt = Date.parse(d)
  const now = Date.now()
  const alertDays = 30
  const printed = printDate(d)
  if (dt < (now - alertDays * 24 * 3600 * 1000)) {
    return <span style={{ color: "red" }}>{printed}</span>
  }
  return <span>{printed}</span>
}

const initState = {
  name: '',
  date: 'desc',
}

export default function StatsListComp() {
  const navigate = useNavigate()

  const [state, dispatch] = useReducer(genReducer(initState), initState)

  const [search, setSearch] = useState('')
  const debSearch = useDebounce(search, 300)

  const lastSyncedData = dataApi.useGetStatsLastSyncedQuery()
  useCheckQueryRes(lastSyncedData, 'Last Synced')

  const [updateEdgeStat, updateEdgeStatRes] = dataApi.useUpdateEdgeStatMutation()
  useCheckRes(updateEdgeStatRes, 'Update edge stat')

  const dataInShow = useMemo(() => {
    if (!lastSyncedData.data) return []
    // console.log(lastSyncedData.data)
    let res = Object.keys(lastSyncedData.data).map(k => ({
      name: k,
      date: lastSyncedData.data ? lastSyncedData.data[k].InfluxLastSync : '0001-01-01T00:00:00Z',
      Note: lastSyncedData.data ? lastSyncedData.data[k].Note : '',
      UpdatedAt: lastSyncedData.data ? lastSyncedData.data[k].UpdatedAt : '0001-01-01T00:00:00Z',
      LastUpdateStatAt: lastSyncedData.data ? lastSyncedData.data[k].LastUpdateStatAt : '0001-01-01T00:00:00Z',
    }))
    if (debSearch) {
      res = res.filter(d => d.name.includes(debSearch))
    }
    // console.log(state)
    if (state.name !== '') {
      res.sort((a, b) => {
        const da = a.name
        const db = b.name
        if (state.name === 'desc') {
          return da > db ? -1 : 1
        } else {
          return da > db ? 1 : -1
        }
      })
    }
    if (state.date !== '') {
      res.sort((a, b) => {
        const da = Date.parse(a.date)
        const db = Date.parse(b.date)
        if (state.date === 'desc') {
          return da > db ? -1 : 1
        } else {
          return da > db ? 1 : -1
        }
      })
    }
    return res
  }, [lastSyncedData.data, debSearch, state])

  return (
    <Box>
      <Box
        sx={{ margin: '1em' }}
      >
        <TextField
          label="search"
          value={search}
          onChange={(e : ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
        />
        <ToggleButton
          {...{
            state,
            dispatch,
          }}
        />
        <Button
          variant="outlined"
          onClick={() => navigate("/stats")}
          sx={{ marginLeft: '1em' }}
        >
          Stats
        </Button>
      </Box>

      <Box>
        {lastSyncedData.data && (
          <List
            dense={true}
            subheader={
              <ListSubheader component="div">
                Last synced datetime
              </ListSubheader>
            }
          >

            {dataInShow && dataInShow.map(d => (
              <ListItem key={d.name}>

                <ListItemButton
                  onClick={async () => { await updateEdgeStat(d.name) }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      {d.name[0]}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <span>{d.name}</span>
                        {
                          d.Note !== '' &&
                          <span style={{
                            color: 'red',
                            fontWeight: 'bold',
                            marginLeft: '1em',
                          }}>
                            {d.Note}
                          </span>
                        }
                        {' / '}
                        <span style={{ fontSize: 'small', color: '#888' }}>
                          influxdb-sql-at: {printDate(d.LastUpdateStatAt)}
                        </span>
                      </>
                    }
                    secondary={<>
                      <span>
                        LastSyncedToInfluxAt: {alertDate(d.date)}
                      </span>
                      {' / '}
                      <span>
                        EdgeUpdatedAt: {printDate(d.UpdatedAt)}
                      </span>
                    </>}
                  />
                </ListItemButton>

              </ListItem>
            ))}
          </List>
        )
        }
      </Box>
    </Box>
  )
}
