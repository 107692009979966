
import {
  useState,
  useEffect,
} from 'react'

import { useSetSnack } from 'Components/Snackbar/slice'

// import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
// import type { SerializedError } from '@reduxjs/toolkit'

import {
  useSetLoading,
} from 'Components/Loading'

import {
  useOidc,
  useOidcAccessToken,
  useOidcUser,
} from '@axa-fr/react-oidc'
import { oidcConfigName } from 'myConstants'

import {
  isFetchBaseQueryError,
  isErrorWithMessage,
  FetchBaseQueryError,
} from 'services/rtkHelpers'

export function useDebounce<T>(value : T, delay : number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
                return () => {
                  clearTimeout(handler);
                };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

interface ResType {
  isError : boolean,
  isSuccess : boolean,
  isLoading: boolean,
  error?: FetchBaseQueryError | { message?: string },
}

interface QueryResType {
  isError : boolean,
  isSuccess : boolean,
  isFetching: boolean,
}

export function useAccessToken() {
  const token = useOidcAccessToken(oidcConfigName)
  return token
}
export function useOidc2() {
  const oidc = useOidc(oidcConfigName);
  return oidc
}
export function useOidcUser2() {
  const user = useOidcUser(oidcConfigName);
  return user
}

export function useCheckQueryRes<T extends QueryResType>(res : T, msg : string) {
  const setSnack = useSetSnack()
  const setLoading = useSetLoading()
  useEffect(() => {
    if (!res.isFetching && res.isError) {
      setSnack('error', `${msg} error`)
    }
  }, [res.isFetching, res.isError])
  // console.log("res: ", res)
  // console.log("outside res.isFetching: ", res.isFetching)

  useEffect(() => {
    // console.log("inside res.isFetching: ", res.isFetching)
    if (res.isFetching) {
      setLoading("Loading...")
    }
    else setLoading("")
  }, [res.isFetching])
}

export function useCheckRes<T extends ResType>(res : T, msg : string, successCallback : () => void = () => { }) {
  const setSnack = useSetSnack()
  const setLoading = useSetLoading()
  useEffect(() => {
    if (res.isLoading) setLoading("Loading...")
    else setLoading("")
  }, [res.isLoading])
  useEffect(() => {
    if (res.isSuccess) {
      setSnack('success', `${msg} success`)
      successCallback()
      // setTimeout(()=>navigate('/'), 1000)
    }
  }, [res.isSuccess])
  useEffect(() => {
    if (res.isError) {
      // console.log(res)
      // if (res.error === undefine ) return
      if (isFetchBaseQueryError(res.error)) {
        setSnack('error', `${msg} error ${res.error.status}: ${res.error.data}`) // : ${JSON.stringify(res?.error)}
      } else if (isErrorWithMessage(res.error)) {
        setSnack('error', `${msg} error ${res.error.message}`)
      }

    }
  }, [res.isError])
}

export function useConfirm(callback: ()=>Promise<void>) {
  const [confirm, setConfirm] = useState(false)
  useEffect(()=>{
    if (!confirm) return
    (async ()=>{
      await callback()
      setConfirm(false)
    })()
  }, [confirm])
}
