import {
  // useState,
  SyntheticEvent,
} from 'react';
import Alert from '@mui/material/Alert';
// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
// import SnackbarContent from '@mui/material/SnackbarContent';
// import IconButton from '@mui/material/IconButton';

// import CloseIcon from '@mui/icons-material/Close';

import {
  useSelectSnack,
  useSetSnack,
} from 'Components/Snackbar/slice'

export default function SimpleSnackbar() {

  const { severity, content } = useSelectSnack()
  const setSnack = useSetSnack()

  const open = content !== ""

  const handleClose = (event : SyntheticEvent | Event, reason ?: string) => {
    if (reason === 'clickaway') {
      console.log('clickaway')
      return;
    }
    setSnack("success", "")
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      message={severity}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {content}
      </Alert>
    </Snackbar>
  );
}
