import {
  useState,
  // useReducer,
  // useEffect,
  useLayoutEffect,
  useMemo,
  ChangeEvent,
  // ChangeEvent,
} from 'react'

import Box from '@mui/material/Box'

import { Chart } from 'chart.js'
import { StatCountType } from 'services/data'

let countsChart : null | Chart = null

export default function StatsBar({
  month,
  data,
  details,
} : {
  month : string,
  data : StatCountType[] | undefined,
  details : StatCountType[],
}) {
  useLayoutEffect(() => {
    if (countsChart !== null) return
    countsChart = new Chart(
      window.document.getElementById('statsCounts')! as HTMLCanvasElement,
      {
        type: 'bar',
        data: {
          labels: [],
          datasets: []
        },
        options: {
          indexAxis: 'y',
          maintainAspectRatio: false,
          hover: {
            mode: 'nearest',
            intersect: true,
            axis: 'xy',
            includeInvisible: false
          },
          events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
          plugins: {
            // legend: {
            //   position: 'right',
            // },
            title: {
              display: true,
              text: `Counts in ${month}`
            }
          },
          scales: {
            x: {
              stacked: true,
              beginAtZero: true,
              ticks: {
                autoSkip: false
              },
            },
            y: {
              stacked: true
            },

          },
        }
      }
    )
    return () => {
      if (countsChart === null) return
      countsChart.destroy()
      countsChart = null
    }
  }, [])

  useLayoutEffect(() => {
    // const data = countsData.data
    if (!data || !countsChart) return
    // const keys = Object.keys(data)
    // const totalValuesMR = data.map(d => (d.TotalStudyCounts.MR?.Count || 0))
    // const procedValuesMR = data.map(d => (d.ProcedStudyCounts.MR?.Count || 0))
    // const totalValuesPT = data.map(d => (d.TotalStudyCounts.PT?.Count || 0))
    // const procedValuesPT = data.map(d => (d.ProcedStudyCounts.PT?.Count || 0))

    const totalValuesMR = details.map(d => (d?.Parsed?.Total?.MR?.Count || 0))
    const procedValuesMR = details.map(d => (d?.Parsed?.Matched?.MR?.Count || 0))
    const totalValuesPT = details.map(d => (d?.Parsed?.Total?.PT?.Count || 0))
    const procedValuesPT = details.map(d => (d?.Parsed?.Matched?.PT?.Count || 0))

    const keys = details.map(d => d.Edge.Name)

    // setCount(keys.length)
    // console.log(keys.length)
    // console.log(keys)
    // console.log(totalValuesMR)
    // console.log(countsChart.options)
    countsChart.data = {
      labels: keys,
      datasets: [
        {
          label: "MR-unproced",
          data: totalValuesMR.map((v, i) => v - procedValuesMR[i] < 0 ? 0 : v - procedValuesMR[i]),
          stack: 'stack 0',
        },
        {
          label: "MR-proced",
          data: procedValuesMR,
          stack: 'stack 0',
        },
        {
          label: "PET-unproced",
          data: totalValuesPT.map((v, i) => v - procedValuesPT[i] < 0 ? 0 : v - procedValuesPT[i]),
          stack: 'stack 1',
        },
        {
          label: "PET-proced",
          data: procedValuesPT,
          stack: 'stack 1',
        },
      ]
    }
    if (countsChart?.options?.plugins?.title?.text) {
      countsChart.options.plugins.title.text = `Counts in ${month}`
    }
    countsChart.update()
  }, [data])

  return (
    <Box>
      <canvas id="statsCounts" role='img' style={{ width: "90vw", maxHeight: `1200px` }}></canvas>
    </Box>
  )
}
