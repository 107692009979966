import type { SyntheticEvent } from 'react'
import { Controller, ControllerProps, FieldValues } from "react-hook-form"

import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

type ControllerPart<T extends FieldValues> = Pick<ControllerProps<T>, "name" | "control" | "rules" | "defaultValue">

export default function RHFAutocomplete<T extends FieldValues>({ options, control, name, rules, defaultValue, setIsManualInput, ...props } : ControllerPart<T> & TextFieldProps & {
  options : string[],
  setIsManualInput ?: (flag : boolean) => void,
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState: { isValid, errors },
      }) => {
        // console.log(error)
        return (
          <Autocomplete
            options={options}
            freeSolo={true}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option}
              </Box>
            )}
            fullWidth={props.fullWidth || true}
            onChange={(e : SyntheticEvent, v : string | string[] | null) => {
              if (v === null) v = ""
              if (setIsManualInput && e?.type === 'change' && v !== "") setIsManualInput(true)
              if (setIsManualInput && v === "") setIsManualInput(false)
              onChange(v)
            }}
            onInputChange={(e : SyntheticEvent, v : string | string[] | null) => {
              if (v === null) v = ""
              if (setIsManualInput && e?.type === 'change' && v !== "") setIsManualInput(true)
              if (setIsManualInput && v === "") setIsManualInput(false)
              onChange(v)
            }}
            onBlur={onBlur}
            value={value}
            renderInput={(params) => {
              return (
                //                   {...props}
                //                   inputRef={ref}
                <TextField
                  {...params}
                  label={props.label}
                  variant={props.variant || 'standard'}
                  error={invalid || Boolean(error)}
                  helperText={JSON.stringify(error?.message)}
                />
              )
            }}
          />
        )
      }}
    />
  )
}
