import {
  useState,
  // useReducer,
  // useEffect,
  // useLayoutEffect,
  useMemo,
  useCallback,
  ChangeEvent,
  // ChangeEvent,
} from 'react'
import type {
  ReactElement,
} from 'react'

import {
  useNavigate,
  useParams,
} from 'react-router-dom'

// import qs from 'qs'

import Box from '@mui/material/Box'
//import Container from '@mui/material/Container'

import Typography from '@mui/material/Typography'
//import Divider from '@mui/material/Divider'

import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
//import AddIcon from '@mui/icons-material/Add';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

// import PrettyJson from 'Components/PrettyJson'

import BodyParts from './BodyParts'

// import TextField from '@mui/material/TextField'

import { DateTime } from 'luxon'

import {
  useCheckQueryRes,
  useCheckRes,
  // useDebounce,
} from 'utils/hooks'

import type { StatCountType, CountOutput } from 'services/data'

import dataApi from 'services/data'

import { BE_URL } from 'config'

import StatBar from './Bar'
import StatsTotalLine from './StatsTotalLine'

function formatStatCounts(data : StatCountType) : ReactElement {
  const parsed = data.Parsed
  if (!parsed) return <></>
  let keys = Object.keys(parsed.Matched)
  if (keys.length === 0) {
    keys = Object.keys(parsed.Total)
  }
  const days = data.Days?.split(' ') || [0, 0]

  if (keys.length === 0) {
    return (
      <Box>
        <span>
          <b>Total</b>: {`Days ${days[0]} / ${days[1]}. Processed ${data.Total || 0}`}
        </span>
      </Box>
    )
  }

  return <>
    <Box>
      <span>
        <b>Total</b>: {`Days ${days[0]} / ${days[1]}. Processed ${data.Total || 0}`}
      </span>
    </Box>

    {keys.map(k => {
      const matchedMedian = parsed.Matched[k]?.Average || 0
      const totalMedian = parsed.Total[k]?.Average || 0
      return (
        <Box key={k}>
          <span>
            <b>{k}</b>: {parsed.Matched[k]?.Count || 0} / {parsed.Total[k]?.Count || 0}; median {matchedMedian} / {totalMedian < matchedMedian ? matchedMedian : totalMedian}
          </span>
        </Box>
      )
    })}
  </>
}

function laterThanNow(dt : DateTime) {
  const { months } = dt.diffNow('months').toObject()
  if (months && months > 0) {
    return true
  }
  return false
}


export function parseStats(data : StatCountType[]) {
  const details = data.filter(d => d.Key === 'detail').map(d => {
    // if (d.Edge.ID === 25) {
    //   debugger
    // }
    const p = JSON.parse(d.Data)
    let output = {
      ...d,
      Parsed: p as StatCountType["Parsed"]
    }

    const days = data.find(d2 => d2.Edge.Name === d.Edge.Name && d2.Key === 'days' && d.Month === d2.Month)
    if (!!days) {
      output.Days = days!.Data
    }

    const total = data.find(d2 => d2.Edge.Name === d.Edge.Name && d2.Key === 'total' && d.Month === d2.Month)
    if (!!total) {
      output.Total = total!.Data
    }

    const bodyParts = data.find(d2 => d2.Edge.Name === d.Edge.Name && d2.Key === 'bodyParts' && d.Month === d2.Month)
    if (!!bodyParts) {
      output.BodyParts = JSON.parse(bodyParts!.Data) as StatCountType["BodyParts"]
    }

    return output
  })
  return details
}

export default function StatsListComp() {
  const navigate = useNavigate()

  // const [state, dispatch] = useReducer(genReducer(initState), initState)

  // const [search, setSearch] = useState('')
  // const debSearch = useDebounce(search, 1000)

  const routeParams = useParams()

  const month = useMemo(() => {
    if (!routeParams.month || routeParams.month === "") {
      const today = new Date()
      // const [month, setMonth] = useState(today.toISOString().split('-').slice(0, 2).join('-'))
      return today.toISOString().split('-').slice(0, 2).join('-')
    } else {
      return routeParams.month
    }
  }, [routeParams.month])

  const setMonth = useCallback((month : string) => {
    navigate(`/stats/${month}`)
  }, [])

  // console.log(month)

  const countsData = dataApi.useGetStatsCountsQuery(month)
  useCheckQueryRes(countsData, "Get stats counts")

  const totalData = dataApi.useGetStatsTotalQuery()
  useCheckQueryRes(totalData, "Get stats total")

  const details = countsData?.data !== undefined ? parseStats(countsData?.data) : []
  // console.log("details: ", details)

  const currentMonth = useMemo(() => {
    const dt = DateTime.now()
    return dt.toFormat("yyyy-LL")
  }, [])

  const monthDatetime = useMemo(() => {
    return DateTime.utc(parseInt(month.split('-')[0]), parseInt(month.split('-')[1])).plus({ month: 1 })
  }, [month])

  // console.log(countsData)
  // console.log(totalData)

  const labels = totalData?.data?.MonthCounts ? Object.keys(totalData?.data?.MonthCounts) : []
  const totalStatsData = totalData?.data?.MonthCounts ? Object.values(totalData?.data?.MonthCounts) : []

  const [recalcEdgeMonthStats, recalcEdgeMonthStatsRes] = dataApi.useRecalcEdgeMonthStatsMutation()
  useCheckRes(recalcEdgeMonthStatsRes, "Recalc monthly stats for edge")

  const [updateEdgeBodyParts, updateEdgeBodyPartsRes] = dataApi.useUpdateEdgeBodyPartsMutation()
  useCheckRes(updateEdgeBodyPartsRes, "Update edge body parts")

  const getStatsAlertRes = dataApi.useGetStatsAlertQuery(month)
  useCheckQueryRes(getStatsAlertRes, "Get stats alert")
  const [deleteStatsAlert, deleteStatsAlertRes] = dataApi.useDeleteStatsAlertMutation()
  useCheckRes(deleteStatsAlertRes, `Regenerate statsAlert in month ${month}`)

  return (
    <Box>
      <StatsTotalLine
        labels={labels}
        data={[{
          label: `month`,
          data: totalStatsData,
        }]}
        title={`We have served for ${totalData?.data?.Total} patients!`}
      />

      <hr />

      <Box
        sx={{ margin: '1em', display: 'flex', justifyContent: 'center' }}
      >
        <Box>
          <IconButton
            onClick={() => {
              const dt = DateTime.utc(parseInt(month.split('-')[0]), parseInt(month.split('-')[1])).plus({ month: -1 })
              setMonth(dt.toFormat("yyyy-LL"))
            }}
            sx={{ marginLeft: '1em' }}
          >
            <ArrowLeftIcon />
          </IconButton>

          <input
            type="month"
            value={month}
            min="2020-01"
            max={currentMonth}
            step="1"
            onChange={(e : ChangeEvent<HTMLInputElement>) => {
              console.log(e.currentTarget.value)
              setMonth(e.currentTarget.value)
            }}
          />

          <IconButton
            onClick={() => {
              if (laterThanNow(monthDatetime)) {
                setMonth(currentMonth)
              } else {
                setMonth(monthDatetime.toFormat("yyyy-LL"))
              }
            }}
            disabled={laterThanNow(monthDatetime)}
            sx={{ marginLeft: '1em' }}
          >
            <ArrowRightIcon />
          </IconButton>
        </Box>
      </Box >

      <Box>
        <Button
          variant="contained"
          onClick={() => {
            window.location.href = `${BE_URL}/static/statsAlert-default.html`
          }}
        >
          Alert Report
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            deleteStatsAlert(month)
          }}
        >
          Regenerate
        </Button>
      </Box>

      <StatBar
        month={month}
        data={countsData.data}
        details={details}
      />

      <Box style={{ marginTop: '1em' }}>
        <Typography style={{ backgroundColor: 'lightyellow', padding: '1em' }}>
          Format: (Modality): (Processed)/(Total), median (Processed per day)/(Total per day). Days (Processed)/(Total)
        </Typography>

        {details.length > 0 && (
          <ul>
            {details.map(d => {
              const k = d.Edge.Name
              // console.log(k, d.BodyParts)
              return (
                <div key={k} style={{ marginBottom: '0.5em', borderTop: '1px dashed #ddd' }}>
                  <Typography>{d.Edge.Hospital.Name}, <a href={`/edge/${d.Edge.ID}`}>{k}</a>, edgeID: {d.Edge.ID}</Typography>
                  <Box>{formatStatCounts(d)}</Box>
                  {d?.BodyParts && <Box><BodyParts data={d!.BodyParts} /></Box>}
                  <Box>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        recalcEdgeMonthStats({ id: d.Edge.ID, month })
                      }}
                    >Recalculate</Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        updateEdgeBodyParts({ edgeId: d.Edge.ID, month })
                      }}
                    >Update bodyParts</Button>
                  </Box>
                </div>
              )
            })}
          </ul>
        )
        }
      </Box>
    </Box >
  )
}
