
import {
  useEffect,
  useState,
  useCallback,
  createContext,
  useContext,
  //useMemo,
} from 'react'

import {
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom'

import {
  //  Controller,
  //  ControllerProps,
  Control,
  //  FieldValues,
  useForm,
  SubmitHandler,
  useFieldArray,
  useWatch,
  UseFieldArrayReturn,
} from "react-hook-form"

import Box from '@mui/material/Box'
// import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

import Paper from '@mui/material/Paper'

import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

import Typography from '@mui/material/Typography'

import {
  useDebounce,
  useCheckRes,
  useCheckQueryRes,
} from 'utils/hooks'

import RHFTextField from 'Components/Forms/TextField'
import RHFAutocomplete from 'Components/Forms/Autocomplete'
import ConfirmDialog from 'Components/Dialog'

// import { useSetSnack } from 'Components/Snackbar/slice'

import dataApi from 'services/data'

import { BE_URL } from 'config'

import type {
  Hospital,
} from 'features/hospital/types'

import PROVINCES from 'utils/provinces'

const required = {
  value: true,
  message: 'This item is required'
}
function minLengthGen(l : number) : { value : number, message : string } {
  return {
    value: l,
    message: `Not fulfill min length of ${l}`,
  }
}

function App(
  { control, index } :
    {
      control : Control<Hospital>,
      index : number,
    }) {

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray<Hospital>({
    control,
    name: `Edges.${index}.Apps`,
  });

  return (
    <Grid item md={12} container>
      {fields.map((field, appIndex) => {
        return (
          <Card key={field.id} sx={{ width: '45%' }}>
            <CardHeader
              sx={{ padding: 0 }}
              action={
                <IconButton
                  aria-label="Delete"
                  onClick={() => remove(index)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            />
            <CardContent
              sx={{ padding: 0 }}
            >
              <RHFAutocomplete
                name={`Edges.${index}.Apps.${appIndex}.App`}
                control={control}
                rules={{ required, minLength: minLengthGen(4) }}
                defaultValue="SubtleMR"
                label='App Name'
                variant='outlined'
                options={['SubtleMR', 'SubtlePET']}
              />
            </CardContent>
          </Card>
        );
      })}

      <Box>
        <Button
          onClick={() => {
            append({
              App: 'SubtleMR',
            })
          }}
          startIcon={<AddIcon />}
        >
          App
        </Button>
      </Box>
    </Grid>
  )
}

function Edge(
  { control, index, id, edgesFieldArray } :
    {
      control : Control<Hospital>,
      index : number,
      id : string,
      edgesFieldArray : UseFieldArrayReturn<Hospital, "Edges", "id">
    }) {

  // const { fields, prepend, remove, swap, move, insert } = useFieldArray({
  //   control,
  //   name: "Edges",
  // });
  // console.log("edge fields: ", fields)
  const { remove } = edgesFieldArray

  const hospitalId = useContext(HospitalIdContext)

  const name = useWatch({ control, name: `Edges.${index}.Name` })
  const edgeRes = dataApi.useGetEdgeQuery(name)
  let err = ""
  // console.log("edgeRes: ", edgeRes)
  //@ts-ignore
  // console.log(edgeRes.data?.Hospital?.ID)
  //@ts-ignore
  if (edgeRes.isSuccess && edgeRes.data.ID && edgeRes.data?.Hospital?.ID !== hospitalId) {
    //@ts-ignore
    err = `Edge already exists: id=${edgeRes.data.ID}, hospital: ${edgeRes.data?.Hospital?.Name}, ${edgeRes.data?.Hospital?.Code}`
  }
  else {
    err = ""
  }
  return (
    <Grid item md={12} xs={12} key={id}>
      <Grid container spacing={1} sx={{
        padding: 0,
        border: 'dotted black 1px',
        position: 'relative',
      }}>
        <Grid item md={6}>
          <RHFTextField
            name={`Edges.${index}.Name`}
            control={control}
            rules={{ required, minLength: minLengthGen(4) }}
            defaultValue=""
            label='Edge Name'
          />
          {err !== "" && < div style={{ color: 'red' }}>
            {err}
          </div>}
        </Grid>

        <App index={index} control={control} />

        <IconButton
          sx={{ position: 'absolute', right: '0' }}
          onClick={() => {
            console.log("remove index: ", index)
            remove(index)
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid >
  );
}

function Edges(
  { control, hospitalCode } :
    {
      control : Control<Hospital>,
      hospitalCode : string,
    }) {

  // { fields, append, prepend, remove, swap, move, insert }
  const edgesFieldArray = useFieldArray({
    control,
    name: "Edges",
  });
  const fields = edgesFieldArray.fields
  const append = edgesFieldArray.append
  // console.log("edges fields: ", fields)

  return (
    <>
      <Grid
        item
        md={12}
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          variant='outlined'
          onClick={() => {
            append({
              Name: `${hospitalCode}-${fields.length + 1}`,
              Apps: []
            })
          }}
        >
          Add edge
        </Button>
      </Grid>

      {fields.map((field, index) => {
        return <Edge
          key={field.id}
          {...{
            control,
            index,
            id: field.id,
            edgesFieldArray,
          }}
        />
      })}
    </>
  )
}

const HospitalIdContext = createContext(undefined as number | undefined)

export default function HospitalComp() {

  const { id } = useParams()
  const { data } = dataApi.useGetHospitalQuery(id || 'undefined')

  const { state } = useLocation()
  console.log("state: ", state)
  const backUrl = `/?search=${state.search}&page=${state.page}`

  const navigate = useNavigate()
  // const setSnack = useSetSnack()

  const [newHospital, newHospitalRes] = dataApi.useNewHospitalMutation()
  const [updateHospital, updateHospitalRes] = dataApi.useUpdateHospitalMutation()

  useCheckRes(newHospitalRes, `Create hospital`, () => setTimeout(() => navigate(backUrl), 1000))
  useCheckRes(updateHospitalRes, `Update hospital`, () => setTimeout(() => navigate(backUrl), 1000))

  const [codeOptions, setCodeOptions] = useState([] as string[])

  const { handleSubmit, watch, control, setValue, getValues, formState } = useForm<Hospital>({
    defaultValues: data,
    values: data,
  });

  const [codeIsManualInput, setCodeIsManualInput] = useState(!!id)

  const hospitalName = useDebounce(watch('Name'), 1000)
  useEffect(() => {
    (async () => {
      if (hospitalName === '' || hospitalName === undefined) {
        // Don't automatically update code for now.
        // setValue('code', '')
        return
      }
      if (id !== undefined && !Object.keys(formState.dirtyFields).includes('name')) {
        return
      }
      try {
        const res = await (await fetch(`${BE_URL}/pinyin?zh=${hospitalName}`)).json()
        // console.log("codeOptions: ", res?.data)
        setCodeOptions(res?.data || [])
        // Don't automatically update code for now.
        if (!codeIsManualInput) setValue('Code', res?.data?.[0])
      } catch (e) {
        console.error(e)
        return
      }
    })()
  }, [hospitalName, setValue])

  const hospitalCode = watch('Code')
  // Don't automatically update edgeName for now.
  // const hospitalCode = useDebounce(watch('code'), 1000)
  // useEffect(() => {
  //   (async () => {
  //     if (hospitalCode === '' || hospitalCode === undefined) {
  //       const edges = getValues('edges')
  //       edges.forEach((_, i) => {
  //         setValue(`edges.${i}.name`, '')
  //       })
  //       return
  //     } else {
  //       const edges = getValues('edges')
  //       edges.forEach((_, i) => {
  //         setValue(`edges.${i}.name`, `${hospitalCode}-${i + 1}`)
  //       })
  //       return
  //     }
  //   })()
  // }, [hospitalCode, setValue])

  // const [dialogContent, setDialogContent] = useState("")
  const [formData, setFormData] = useState(null as null | Hospital)
  const handleOk = useCallback((formData : Hospital | null) => {
    if (!id || !formData) return
    updateHospital({ id, body: formData })
    // setDialogContent("")
  }, [id, updateHospital])
  const handleCancel = useCallback(() => {
    // setDialogContent("")
    setFormData(null)
  }, [setFormData])
  // }, [setDialogContent])

  const onSubmit : SubmitHandler<Hospital> = data => {
    // console.log(data);
    if (id) {
      setFormData(data)
    } else {
      newHospital(data)
    }
  }

  return (
    <HospitalIdContext.Provider value={id ? parseInt(id) : undefined}>
      <Paper
        component='form'
        elevation={0}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          margin: '1em',
          padding: '1em',
          width: '600px',
        }}
      >
        <ConfirmDialog
          title="Warning"
          content={"任何edge name变更会自动触发Influxdb桶名变更，请做好相应更改，确定修改吗？"}
          data={formData}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
        <Grid
          container
          spacing={3}
        >
          <Grid item md={12} sm={12} xs={12}>
            <Typography variant='h6'>
              {id ? 'Edit hospital' : 'Add new hospital'}
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <RHFTextField
              name='Name'
              control={control}
              rules={{ required, minLength: minLengthGen(4) }}
              defaultValue=""
              label='Hospital Name'
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <RHFAutocomplete
              name='Code'
              control={control}
              rules={{ required, minLength: minLengthGen(4), }}
              defaultValue={codeOptions.length > 0 ? codeOptions[0] : ''}
              inputProps={{ readOnly: false }}
              label='Hospital Code'
              variant='outlined'
              options={codeOptions}
              setIsManualInput={setCodeIsManualInput}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <RHFAutocomplete
              name='Province'
              control={control}
              rules={{ required, }}
              defaultValue={!!PROVINCES && PROVINCES.length > 0 ? PROVINCES[0] : ''}
              inputProps={{ readOnly: false }}
              label='Region'
              variant='outlined'
              options={PROVINCES || []}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <RHFTextField
              name='S3Name'
              control={control}
              rules={{}}
              defaultValue={data?.S3Name || data?.Name}
              inputProps={{ readOnly: false }}
              label='S3Name'
              variant='outlined'
            />
          </Grid>

          <Edges
            control={control}
            hospitalCode={hospitalCode}
          />

          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              type="submit"
              variant='contained'
            >
              Submit
            </Button>
            <Button
              variant='outlined'
              color='secondary'
              onClick={() => navigate(backUrl)}
              sx={{ marginLeft: '1em' }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </HospitalIdContext.Provider>
  )
}
