
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// import type { Post } from './types'

import type {
  AuthType,
} from 'features/auth/slice'

import { BE_URL } from 'config'

// https://pkg.go.dev/golang.org/x/oauth2#Token
export interface LoginReq {
  access_token : string,
  refresh_token : string,
  token_type : string,
  expires_in : string,
  id_token : string,
}

export interface LoginRes {
  token : string,
  username : string,
  email : string,
}

// interface CheckTokenReq {
//   token: string,
//   email: string,
// }

export const api = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BE_URL}`,
  }),
  tagTypes: ['UNAUTHED'],
  endpoints: (build) => ({

    login: build.mutation<LoginRes, LoginReq>({
      query: (body) => ({
        url: `login`,
        method: 'POST',
        body,
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      invalidatesTags: (result) => (result ? ['UNAUTHED'] : []),
    }),

    checkAuth: build.mutation<void, AuthType>({
      query: (body) => ({
        url: `checkAuth`,
        method: 'POST',
        body,
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      // invalidatesTags: (result) => (result ? ['UNAUTHED'] : []),
    }),

  }),
})

export default api
