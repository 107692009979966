import {
  useState,
  useEffect,
  // useRef,
  ReactNode,
  MouseEvent,
} from 'react'

import Typography from '@mui/material/Typography'
// import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import CardActionArea from '@mui/material/CardActionArea'
import Collapse from '@mui/material/Collapse'

import Avatar from '@mui/material/Avatar'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
// import { red } from '@mui/material/colors'
import { styled } from '@mui/material/styles'

import Menu from 'Components/Menu';

interface ExpandMoreProps extends IconButtonProps {
  expand : boolean
}

const ExpandMore = styled((props : ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default function MultiActionAreaCard({
  title,
  subheader,
  content,
  editAction,
  deleteAction,
  onClick,
  collapse,
} : {
  title : string | ReactNode,
  subheader : string | ReactNode,
  content : string | ReactNode,
  editAction ?: () => void,
  deleteAction ?: () => void,
  onClick ?: () => void,
  collapse : boolean,
}) {
  const [expanded, setExpanded] = useState(!collapse)
  useEffect(() => {
    setExpanded(!collapse)
  }, [collapse])
  const handleExpandClick = () => {
    if (onClick) onClick()
    else setExpanded(!expanded)
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event : MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card sx={{ position: 'relative', marginBottom: '1em' }}>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        }
        title={
          <Box
            onClick={handleExpandClick}
          >
            {title}
          </Box>}
        subheader={
          <Box
            onClick={handleExpandClick}
          >
            {subheader}
          </Box>}
        sx={{ backgroundColor: '#eee' }}
      />

      {content !== "" && <ExpandMore
        sx={{
          position: 'absolute',
          left: '50%',
          top: '60px',
        }}
        expand={expanded}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
      >
        <ExpandMoreIcon />
      </ExpandMore>}

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {content}
        </CardContent>
      </Collapse>

      <Menu
        anchorEl={anchorEl}
        handleClose={handleClose}
        actions={[{
          name: 'Edit',
          action: editAction || (() => { }),
        }, {
          name: 'Delete',
          action: deleteAction || (() => { }),
        }]}
      />
    </Card>
  )
}
