import {
  useState,
  useReducer,
  MouseEvent as ReactMouseEvent,
} from 'react'

import ToggleButton from '@mui/material/ToggleButton'

type ActionType = {
  type : 'field'
  fieldName : string
  payload : any
} | {
  type : 'reset'
}

export function genReducer<T>(initialState : T) {
  return function(state : T, action : ActionType) {
    switch (action.type) {
      case 'field': {
        return {
          ...state,
          [action.fieldName]: action.payload,
        }
      }
      case 'reset':
        return initialState
      default:
        throw new Error()
    }
  }
}

function genAction(field : string, value : any) {
  return {
    type: 'field',
    fieldName: field,
    payload: value,
  } as ActionType
}
function nextStatus(status : string) {
  if (status === '') return 'desc'
  if (status === 'desc') return 'asc'
  if (status === 'asc') return ''
  else return ''
}

export default function ToggleButtonComp<T extends { [key : string] : string }>({
  state,
  dispatch,
} : {
  state : T,
  dispatch : any,
}) {
  // const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <>
      {Object.keys(state).map(k =>
        <ToggleButton
          key={k}
          value={k}
          selected={state[k] !== ''}
          onClick={() => dispatch(genAction(k, nextStatus(state[k])))}
        >{k} {state[k] && `(${state[k] === 'asc' ? '↑' : '↓'})`}</ToggleButton>
      )}
    </>
  )
}
