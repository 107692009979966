// import {
//   useState,
// } from 'react'
// import type {
//   MouseEvent,
// } from 'react'

// import Button from '@mui/material/Button';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import MenuList from '@mui/material/MenuList';

export default function PositionedMenu({
  anchorEl,
  handleClose,
  actions,
} : {
  anchorEl : null | HTMLElement,
  handleClose : () => void,
  actions : {
    name : string,
    action : () => void,
  }[]
}) {
  const open = Boolean(anchorEl);

  return (
    <Menu
      aria-labelledby="positioned-button"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {actions && actions.map(a =>
        <MenuItem
          key={a.name}
          onClick={() => {
            a.action()
            handleClose()
          }}
        >{a.name}</MenuItem>
      )}
    </Menu>
  );
}
