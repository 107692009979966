import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useOidc2 as useOidc } from 'utils/hooks'

// import {
//   useSelectAuth,
// } from 'features/auth/slice'

export default function Index() {
  const location = useLocation()
  // const auth = useSelectAuth()
  const { isAuthenticated } = useOidc();
  const ele = isAuthenticated
    ? <Outlet />
    : <Navigate
      to={`/login`}
      replace
      state={{ from: location }}
    />;

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return ele;
}
