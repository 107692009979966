
import {
  useState,
} from 'react'

import { ChangeEvent } from 'react'
import dataApi from 'services/data'
import {
  useCheckQueryRes,
  useCheckRes,
} from 'utils/hooks'

export default function Admin() {

  const getRegionsRes = dataApi.useGetRegionsQuery()
  useCheckQueryRes(getRegionsRes, "Get regions")

  const [createRegion, createRegionRes] = dataApi.useCreateRegionMutation()
  useCheckRes(createRegionRes, `Create region`)

  const [updateRegion, updateRegionRes] = dataApi.useUpdateRegionMutation()
  useCheckRes(updateRegionRes, `Update region`, () => setEditId(""))

  const [newName, setNewName] = useState("")

  const [editId, setEditId] = useState("")
  const [editName, setEditName] = useState("")

  return (
    <>
      <h2>Region</h2>
      <div>
        {getRegionsRes.data && <ul>
          {
            getRegionsRes.data.map(d => {
              return (
                <li key={d.ID}>

                  {editId !== d.ID && <>
                    <span>{d.Name}</span>
                    <button
                      onClick={() => {
                        if (!!d.ID && d.Name !== undefined) {
                          setEditId(d.ID)
                          setEditName(d.Name)
                        }
                      }}
                    >Edit</button>
                  </>}

                  {editId === d.ID && <>
                    <input
                      name="editRegion" type="text"
                      value={editName}
                      onChange={(e : ChangeEvent<HTMLInputElement>) => {
                        const v = e.currentTarget.value
                        setEditName(v)
                      }}
                    />
                    <button
                      onClick={() => {
                        if (!d.ID) return
                        updateRegion({
                          id: d.ID,
                          body: {
                            Name: editName,
                          }
                        })
                      }}
                    >Confirm</button>
                    <button
                      onClick={() => setEditId("")}
                    >Cancel</button>
                  </>}
                </li>
              )
            })
          }
        </ul>}

        <div>
          <input
            name="createRegion" type="text"
            value={newName}
            onChange={(e : ChangeEvent<HTMLInputElement>) => {
              const v = e.currentTarget.value
              setNewName(v)
            }}
          />
          <button
            onClick={() => {
              createRegion({
                Name: newName,
              })
            }}
          >Add region</button>
        </div>
      </div>
    </>
  )
}
