
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { RootState } from 'app/store';

// import {
//   //   useState,
//   //   useEffect,
// } from 'react';

// import Box from '@mui/material/Box';
// import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';

import CircularProgress, {
  // CircularProgressProps,
} from '@mui/material/CircularProgress';
import LinearProgress, {
  // LinearProgressProps,
} from '@mui/material/LinearProgress';

// import Button from '@mui/material/Button';
// import Fab from '@mui/material/Fab';

// import { green } from '@mui/material/colors';

//---------- Slice

export interface DataType {
  data : number;
}

const initialState : DataType = {
  data: 0,
}

export const slice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    setData: (state, action : PayloadAction<number>) => {
      state.data = action.payload;
    },
  },
});

const selectData = (state : RootState) => state.progress.data;
export function useSelectProgress() {
  return useAppSelector(selectData)
}

const {
  setData,
} = slice.actions;
export function useSetProgress() {
  const dispatch = useAppDispatch()
  return (data : number) => {
    dispatch(setData(data))
  }
}

export const reducer = slice.reducer;


//---------- Component

export default function ProgressDeterminate({
  type = 'linear',
} : {
  type ?: 'linear' | 'circular',
}) {
  // const [progress, setProgress] = useState(0);

  const progress = useSelectProgress()

  if (type === 'linear') {
    return (
      <LinearProgress variant="determinate" value={progress} />
    );
  }
  else {
    return (
      <CircularProgress variant="determinate" value={progress} />
    );
  }
}

