// import {
//   useEffect
// } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import PrivateRoute from 'Components/PrivateRoute'
import AppBar from 'Components/AppBar'

import Hospital from 'features/hospital'
import HospitalNew from 'features/hospital/new'
import Edge from 'features/hospital/edge'

import Stats from 'features/stats'
import StatsLastSync from 'features/stats/LastSync'

import Snackbar from 'Components/Snackbar'
import Loading from 'Components/Loading'
import Progress from 'Components/Progress'

import Auth from 'Components/Auth'

import Check from 'features/stats/Check'
import Admin from 'features/admin'

import './App.css';

import {
  Chart,
  Colors,
  BarController,
  BarElement,
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Legend,
  Title,
  Tooltip,
} from 'chart.js'

Chart.register(
  Colors,
  BarController,
  BarElement,
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Legend,
  Title,
  Tooltip,
);

export default function Index() {

  // <PrivateRoute/>
  return (
    <Router>
      <CssBaseline />
      <Auth />
      <AppBar />
      <Snackbar />
      <Loading />
      <Progress />
      <Container sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}>
        <Routes>
          <Route path='/' element={<PrivateRoute />}>
            <Route path='/' element={<Hospital />} />
            <Route path='/hospital' element={<Hospital />} />
            <Route path='/hospital/new' element={<HospitalNew />} />
            <Route path='/hospital/:id' element={<HospitalNew />} />
            <Route path='/edge/:id' element={<Edge />} />
            <Route path='/stats/' element={<Stats />} />
            <Route path='/stats/lastSync' element={<StatsLastSync />} />
            <Route path='/stats/:month' element={<Stats />} />
            <Route path='/check' element={<Check />} />
            <Route path='/admin' element={<Admin />} />
          </Route>
          <Route path='/login' element={<h3>Please login first</h3>} />
        </Routes>
      </Container>
    </Router>
  )
}
