import { useMemo } from 'react'

import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import type { BodyPartsType, StatCountType } from 'services/data'

function SingleRow({
  title,
  data,
} : {
  title : string,
  data : BodyPartsParsedInnerType,
}) {
  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography>{title}: {data.total}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {data.data.map(d => (
          <>
            <Typography>
              {d[0]}: {d[1]}
            </Typography>
            <br />
          </>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

interface BodyPartsParsedInnerType {
  total : number,
  data : [string, number][],
}
type BodyPartsParsedType = Record<string, BodyPartsParsedInnerType>

export default function BodyParts({
  data,
} : {
  data : StatCountType["BodyParts"],
}) {

  // Change rows to a tree
  const parsed = useMemo(() => {
    let parsed = {} as BodyPartsParsedType
    if (!data) return {}
    data.forEach(d => {
      if (!parsed.hasOwnProperty(d.BodyPartsExamined)) {
        parsed[d.BodyPartsExamined] = {
          total: d.Count,
          data: [[d.ProtocolName, d.Count]],
        }
      } else {
        // The bodyParts count is the max of the protocolName, not the sum.
        // parsed[d.BodyPartsExamined].total += d.Count
        if (parsed[d.BodyPartsExamined].total < d.Count) parsed[d.BodyPartsExamined].total = d.Count
        parsed[d.BodyPartsExamined].data.push([d.ProtocolName, d.Count])
      }
    })
    // console.log("parsed: ", parsed)
    return parsed
  }, [data])

  if (!data) return null

  return (
    <>
      {Object.keys(parsed).map(k => {
        return (
          <SingleRow
            key={k}
            title={k}
            data={parsed[k]}
          />
        )
      })}
    </>
  )
}
