import { Controller, ControllerProps, Control, FieldValues, useForm, SubmitHandler } from "react-hook-form"

import TextField, { TextFieldProps } from '@mui/material/TextField';

type ControllerPart<T extends FieldValues> = Pick<ControllerProps<T>, "name" | "control" | "rules" | "defaultValue">

export default function RHFTextField<T extends FieldValues>({ control, name, rules, defaultValue, ...props } : ControllerPart<T> & TextFieldProps) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState: { isValid, errors },
      }) => (
        <TextField
          {...props}
          variant={props.variant || 'standard'}
          fullWidth={props.fullWidth || true}
          onChange={onChange} onBlur={onBlur} inputRef={ref} value={value} error={invalid || Boolean(errors[name])} helperText={JSON.stringify(errors[name]?.message)} />
      )}
    />
  )
}
