import {
  useState,
  useReducer,
  useEffect,
  useMemo,
  useCallback,
  ChangeEvent,
} from 'react'
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import Box from '@mui/material/Box'
// import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
// import Container from '@mui/material/Container'

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add';

import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import { printDate } from 'utils'
import { useCheckQueryRes, useCheckRes, useDebounce } from 'utils/hooks'
import provinces from 'utils/provinces'

import ConfirmDialog from 'Components/Dialog'

import Dropzone from 'Components/Dropzone'

// import Accordion from 'Components/Accordion'
import Card from 'Components/Card'
import ToggleButton, {
  genReducer,
} from 'Components/ToggleButton'

import { humanFileSize } from 'utils'

import type { Hospital } from 'features/hospital/types'

import dataApi from 'services/data'
import { useUploadToHospital } from 'services/xhr'

const initState = {
  name: '',
  date: '',
}


interface S3Type {
  Filename : string
  Size : number
  LastModified : string
}

function S3comp({
  content,
  name,
} : {
  content : string
  name : string
}) {
  const s3 = JSON.parse(content)
  // console.log(s3)
  return (
    <Box>
      {/*       <pre>{JSON.stringify(s3, null, 2)}</pre> */}

      <List
        dense={true}
        subheader={
          <ListSubheader component="div">
            {`S3 files: ${name}`}
          </ListSubheader>
        }
      >
        {s3 === null ? null : s3.map((value : S3Type) => (
          <ListItem
            key={value.Filename}
            secondaryAction={
              <IconButton edge="end" aria-label="delete">
              </IconButton>
            }
          >
            <ListItemButton selected={false} onClick={() => { }}>
              <ListItemText
                primary={value.Filename}
                secondary={<span>{value.LastModified}, {humanFileSize(value.Size)}</span>}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

    </Box>
  )
}

interface RouteState {
  search : string,
  page : number,
}

function HospitalComp({
  h,
  collapse,
  routeState,
} : {
  h : Hospital,
  collapse : boolean,
  routeState : RouteState,
}) {
  const navigate = useNavigate()

  // console.log("hospital: ", data)
  // const [uploadToHospital, uploadToHospitalRes] = dataApi.useUploadToHospitalMutation()
  // useCheckRes(uploadToHospitalRes, `Upload to hospital`)

  const [deleteId, setDeleteId] = useState(null as string | null)
  const [deleteHospital, deleteHospitalRes] = dataApi.useDeleteHospitalMutation()
  useCheckRes(deleteHospitalRes, `Delete hospital ${h.ID}:${h.Name}`)

  const uploadToHospital = useUploadToHospital()

  return (
    <>
      <ConfirmDialog
        title={"WARN"}
        content={`Make sure you want to delete the hospital ${h.ID}:${h.Name}`}
        data={deleteId}
        handleOk={() => {
          if (deleteId === null) return
          deleteHospital({ id: deleteId })
          navigate("/", { state: routeState })
        }}
        handleCancel={() => setDeleteId(null)}
      />
      <Card
        editAction={() => navigate(`/hospital/${h.ID}`, { state: routeState })}
        deleteAction={() => setDeleteId(h.ID!)}
        title={h.Name}
        subheader={<>{h.Code}, province: {h?.Province || ""}, updated: {h.UpdatedAt}</>}
        collapse={collapse}
        content={
          <>
            <List
              dense={true}
              subheader={null
              }
            >
              {h?.Edges && h?.Edges.map(e => {
                return (
                  <ListItem
                    disablePadding
                    key={e.ID}
                  >
                    <ListItemButton
                      onClick={() => navigate(`/edge/${e.ID}`, { state: routeState })}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          {e.Name.slice(e.Name.length - 1)}
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText
                        primary={e.Name}
                        secondary={<>
                          created at: {printDate(e.CreatedAt)}<br />
                          updated at: {printDate(e.UpdatedAt)}
                        </>}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              })
              }
            </List>

            <Divider />

            <Dropzone
              title={`Upload to h.Name`}
              upload={(files : File[]) => {
                if (h.ID === undefined) return
                const body = new FormData()
                files.forEach(f => body.append('files', f))
                uploadToHospital({ id: h.ID, body })
              }}
            />

            {h?.S3 && <S3comp content={h.S3} name={h.S3Name || h.Name} />}
          </>
        }
      />
    </>
  )
}

export default function HospitalListComp() {
  const navigate = useNavigate()

  const [state, dispatch] = useReducer(genReducer(initState), initState)

  // const getRegionsRes = dataApi.useGetRegionsQuery()
  // useCheckQueryRes(getRegionsRes, `Get regions`)

  const [searchParams, setSearchParams] = useSearchParams()
  // console.log(searchParams)

  const [search, setSearch] = useState(searchParams.get('search') || '')
  const debSearch = useDebounce(search, 1000)

  const limit = 10

  // const [page, setPage] = useState(queryPage === -1 ? 1 : queryPage)
  const page = useMemo(() => {
    const queryPage = parseInt(searchParams.get('page') || '-1')
    if (queryPage === -1) {
      return 1
    } else {
      return queryPage
    }
  }, [searchParams])

  const [businessStatus, setBusinessStatus] = useState('All')
  const [province, setProvince] = useState('All')

  const getHospitalsRes = dataApi.useGetHospitalsQuery({
    limit,
    offset: (page - 1) * limit,
    search: debSearch,
    sortName: state.name,
    sortUpdate: state.date,
    businessStatus,
    province,
  })
  useCheckQueryRes(getHospitalsRes, `Get hospitals`)
  const data = getHospitalsRes.data

  const totalPage = data?.hospitalCount ? Math.floor(Number(data.hospitalCount) / limit) + (Number(data.hospitalCount) % limit >= 1 ? 1 : 0) : 0

  useEffect(() => {
    // setPage(1)
    setSearchParams({ search: debSearch, page: `${page}` })
  }, [state.date, state.name, debSearch])

  const [pageInput, setPageInput] = useState(`${page}`)
  const pageInputAsNum = useMemo(() => {
    return parseInt(pageInput) || page
  }, [pageInput])

  const setPage = useCallback((page : number) => {
    setSearchParams({ search: debSearch, page: `${page}` })
    setPageInput(`${page}`)
  }, [debSearch])

  const [collapse, setCollapse] = useState(false)

  const routeState = {
    search: debSearch,
    page,
  }

  // console.log(data, currentData)
  return (
    <Box>
      <Box
        sx={{ margin: '1em' }}
      >
        <Button
          startIcon={<AddIcon />}
          onClick={() => navigate('/hospital/new', { state: routeState })}
        >Add hospital</Button>
      </Box>

      <Grid
        container
        spacing={{ xs: 1, sm: 1 }}
        rowSpacing={{ xs: 1, sm: 1 }}
        columns={{ xs: 4, sm: 6 }}
        sx={{
          margin: '1em',
        }}
      >
        <Grid item>
          <TextField
            label="search"
            value={search}
            onChange={(e : ChangeEvent<HTMLInputElement>) => {
              setSearch(e.target.value)
              setPage(1)
            }}
          />
          <ToggleButton
            {...{
              state,
              dispatch,
            }}
          />
        </Grid>

        <Grid
          item
          sx={{
            display: 'flex',
            flexDirection: 'row',
            border: '1px dotted #999',
          }}
        >

          <Button
            sx={{ marginLeft: '1em' }}
            disabled={page === 1}
            onClick={() => {
              let nextPage = page - 1
              if (nextPage < 1) nextPage = 1
              setPage(nextPage)
            }}
          >
            Previous
          </Button>

          <Typography
            component="div"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TextField
              label=""
              value={pageInput}
              onChange={(e : ChangeEvent<HTMLInputElement>) => {
                setPageInput(e.target.value)
              }}
              style={{
                width: '3em'
              }}
              size={'small'}
              fullWidth={false}
            /> / {totalPage}
          </Typography>
          <Button
            disabled={page === pageInputAsNum || pageInputAsNum < 1 || pageInputAsNum > totalPage}
            onClick={() => {
              setPage(pageInputAsNum)
            }}
          >
            OK
          </Button>

          <Button
            disabled={page === totalPage}
            onClick={() => {
              let nextPage = page + 1
              if (nextPage > totalPage) {
                nextPage = totalPage
              }
              setPage(nextPage)
            }}
          >
            Next
          </Button>

        </Grid>

        <Grid
          item
          sx={{
            display: 'flex'
          }}
        >
          <TextField
            label="Status"
            select={true}
            value={businessStatus}
            onChange={(e : ChangeEvent<HTMLInputElement>) => {
              setBusinessStatus(e.target.value)
              setPage(1)
            }}
            style={{
              minWidth: '5em',
            }}
            size={'small'}
            fullWidth={false}
          >
            <MenuItem key={1} value={"All"}>
              All
            </MenuItem>
            <MenuItem key={1} value={"inService"}>
              In Service
            </MenuItem>
            <MenuItem key={1} value={"deceased"}>
              Deceased
            </MenuItem>
          </TextField>
          <TextField
            label="Province"
            select={true}
            value={province}
            onChange={(e : ChangeEvent<HTMLInputElement>) => {
              setProvince(e.target.value)
              setPage(1)
            }}
            style={{
              minWidth: '5em',
            }}
            size={'small'}
            fullWidth={false}
          >
            <MenuItem key={0} value={"All"}>
              All
            </MenuItem>
            {
              provinces.map((d, i) => (
                <MenuItem key={i} value={d}>
                  {d}
                </MenuItem>
              ))
            }
          </TextField>
        </Grid>

        <Grid item>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={false}
                onChange={() => {
                  setCollapse(e => !e)
                }}
              />}
            label="Collapse"
          />
        </Grid>

        <Grid item>
          <Box>
            <Typography>
              {`Hospitals: ${data?.hospitalCount || 0}`}
            </Typography>
            <Typography>
              {`Edges: ${data?.edgeCount || 0}`}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box>
        {data?.data && data.data.map((h : Hospital) => {
          // console.log(h.ID)
          return (
            <Box key={h.ID}>
              {h.ID ? <HospitalComp collapse={collapse} h={h} routeState={{ search: debSearch, page }} /> : null}
            </Box>
          )
        })}
      </Box>
    </Box >
  )
}
