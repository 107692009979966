
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type { RootState } from 'app/store'

import { BE_URL } from 'config'

const baseQuery = fetchBaseQuery({
  baseUrl: `${BE_URL}`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token

    /* If we have a token set in state, let us assume that we should be passing it. */
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    return headers
  },
})

export default baseQuery
