// import {
//   useState,
// } from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import Button from '@mui/material/Button';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { RootState } from 'app/store';

//---------- Slice

export interface DataType {
  data : string;
}

const initialState : DataType = {
  data: "",
}

export const slice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setData: (state, action : PayloadAction<string>) => {
      state.data = action.payload;
    },
  },
});

const selectData = (state : RootState) => state.loading.data;
export function useSelectLoading() {
  return useAppSelector(selectData)
}

const {
  setData,
} = slice.actions;
export function useSetLoading() {
  const dispatch = useAppDispatch()
  return (data : string) => {
    dispatch(setData(data))
  }
}

export const reducer = slice.reducer;

//---------- Component

export default function Loading() {
  const loading = useSelectLoading()
  // const setLoading = useSetLoading()
  // console.log("loading: ", loading)
  if (loading === '') return null
  //         onClick={() => setLoading("")}
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
        <div>
          {loading}
        </div>
      </Backdrop>
    </div>
  )
}
