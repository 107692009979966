import {
  // useState,
  // useCallback,
  ReactNode,
} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Dialog, { } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// import TextField from '@mui/material/TextField';

export default function ConfirmDialog<T>({
  title,
  content,
  data,
  handleOk,
  handleCancel,
} : {
  title ?: ReactNode,
  content : string,
  data : T | null,
  handleOk : (data : T | null) => void,
  handleCancel : () => void,
}) {

  const open = data !== null

  return (
    <Box>
      <Dialog open={open} onClose={handleCancel}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={() => {
            handleOk(data)
            // handleCancel()
          }}>OK</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
