
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export function PrettyCodes({
  lang,
  input,
} : {
  lang : string,
  input : string
}) {
  return (
    <SyntaxHighlighter language={lang} customStyle={{ fontSize: 'small' }} style={docco} wrapLines={true} wrapLongLines={true} >
      {input}
    </SyntaxHighlighter >
  )
}

export default function PrettyJson({
  input,
  jsonParse = true,
  wrapArray = false,
  hideKeys = [],
} : {
  input : string,
  jsonParse ?: boolean,
  wrapArray ?: boolean, // It's an array, and we need to wrap it with []
  hideKeys ?: string[],
}) {
  const txt = input.replaceAll(' ', '')
  // console.log(jsonParse, txt)

  let parsed = txt
  if (wrapArray && txt !== '') {
    // console.log(parsed)
    parsed = `[${parsed}`
    parsed = parsed.replaceAll('}', '},')
    parsed = parsed.slice(0, parsed.length - 2)
    parsed = parsed + ']'
    // console.log(parsed)
  }
  try {
    if (jsonParse && txt !== '') {
      parsed = JSON.parse(parsed)
    }
  } catch (e) {
    jsonParse = false
    // console.log(e)
  }

  if (!jsonParse) return <>{parsed}</>

  hideKeys.forEach(k => {
    delete (parsed as any)[k]
  })
  // console.log(`parsed: `, parsed)

  return (
    <SyntaxHighlighter language="json" customStyle={{ fontSize: 'small' }} style={docco} wrapLines={true} wrapLongLines={true} >
      {JSON.stringify(parsed, null, 2)}
    </SyntaxHighlighter >
  )
}

