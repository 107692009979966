import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { RootState, AppThunk } from '../../app/store';

import type { AlertColor } from '@mui/material/Alert'

//---------- Component

export interface SnackType {
  content : string;
  severity : AlertColor;
}

const initialState : SnackType = {
  content: "",
  severity: "success",
}

export const slice = createSlice({
  name: 'snack',
  initialState,
  reducers: {
    setSnack: (state, action : PayloadAction<SnackType>) => {
      return action.payload
    },
  },
});

const selectSnack = (state : RootState) => state.snack;
export function useSelectSnack() {
  return useAppSelector(selectSnack)
}

export const {
  setSnack,
} = slice.actions;

export function useSetSnack() {
  const dispatch = useAppDispatch()
  return (severity : AlertColor, content : string) => {
    dispatch(setSnack({
      severity,
      content,
    }))
  }
}

export default slice.reducer;

