import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

import {
  persistStore,
} from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import { OidcProvider } from '@axa-fr/react-oidc'

import { oidcConfigName } from 'myConstants'

const container = document.getElementById('root')!;
const root = createRoot(container);

let persistor = persistStore(store)

// Don't use default if you want to expost access_token
const configurationName = oidcConfigName

const configuration = {
  client_id: 'ops',
  redirect_uri: window.location.origin + '/authentication/callback',
  silent_redirect_uri: window.location.origin + '/authentication/silent-callback', // Optional activate silent-signin that use cookies between OIDC server and client javascript to restore the session
  scope: 'openid profile email offline_access', //  api
  authority: 'https://chinakc.sandbox.subtlemedical.com/realms/SubtleCN',
  service_worker_relative_url: '/OidcServiceWorker.js',
  service_worker_only: true,
  token_request_extras: {
    client_secret: 'pLFt1nUyecITbftm4gu1ecFevVdGrsLU',
  },
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <OidcProvider
          configuration={configuration}
          configurationName={configurationName}
        >
          <App />
        </OidcProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
