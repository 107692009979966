import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import type {
  AuthType,
} from 'features/auth/slice'

import baseQuery from 'services/baseQuery'

import type {
  Hospital,
  Edge,
  ListQueryType,
} from 'features/hospital/types'

import qs from 'qs'

import type {
  Region,
} from 'types'

interface NewRes {
}

interface GetHospitalsRes {
  data : Hospital[]
  hospitalCount : Number
  edgeCount : Number
}

export interface CountOutput {
  Count: number,
  Average: number,
  Error?: string,
}

export interface BodyPartsType {
  BodyPartsExamined: string,
  ProtocolName: string,
  Count: number,
}

export interface StatCountType {
  ID: string,
  Month: string
  Key: string,
  Data: string,
  EdgeId: number,
  Edge: {
    Name: string,
    ID: string,
    Hospital: {
      Name: string,
    }
  },
  Parsed?: {
    Matched: Record<string, CountOutput>,
    Total: Record<string, CountOutput>,
  },
  BodyParts?: BodyPartsType[],
  Total?: string,
  Days?: string,
}

export interface StatTotalType {
  Total: number
  MonthCounts: Record<string, number>
}

// export interface StatCountsType {
//   TotalStudyCounts : Record<string, CountOutput>,
//   ProcedStudyCounts : Record<string, CountOutput>,
// }


export interface GetStatsLastSyncedRes {
  InfluxLastSync: string
  UpdatedAt: string
  LastUpdateStatAt: string
  Note?: string
}

export interface OrphanBucket {
  ID: string
  Name: string
  CreatedAt: string
  UpdatedAt: string
}

export interface UpdateEdgeBodyPartsInput {
  edgeId: string,
  month: string,
}

export const api = createApi({
  reducerPath: 'dataApi',
  baseQuery,
  tagTypes: ['UNAUTHED', 'Hospitals', 'Stats', 'Edges', 'StatsAlert', 'Regions'],
  endpoints: (build) => ({

    newHospital: build.mutation<NewRes, Hospital>({
      query: (body) => ({
        url: `hospitals`,
        method: 'POST',
        body,
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      invalidatesTags: (result, error, { ID }) => [
        { type: 'Hospitals', id: 'LIST' },
        { type: 'Hospitals', id: ID },
        { type: 'Stats', id: 'LIST' },
      ],
    }),

    getHospitals: build.query<GetHospitalsRes, ListQueryType>({
      query: (query) => ({
        url: `hospitals?${qs.stringify(query)}`,
        method: 'GET',
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      providesTags: (result, _, { limit, offset, search, sortName, sortUpdate }) =>
        result
        ? [
          ...result.data.map(({ ID }) => ({ type: 'Hospitals', id: ID } as const)),
          { type: 'Hospitals', id: 'LIST' },
          { type: 'Hospitals', limit, offset, search, sortName, sortUpdate, },
        ] :
        [
          { type: 'Hospitals', limit, offset, search, sortName, sortUpdate, },
          { type: 'Hospitals', id: 'LIST' },
        ],
    }),

    getHospital: build.query<Hospital, string>({
      query: (id) => {
        // if (id === undefined) return {}
        // else {
        return {
          url: `hospital/${id}`,
          method: 'GET',
        }
        // }
      },
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      providesTags: (result, error, id) => [{ type: 'Hospitals', id }],
    }),

    updateHospital: build.mutation<NewRes, { id : string, body : Hospital }>({
      query: ({ id, body }) => ({
        url: `hospital/${id}`,
        method: 'PUT',
        body,
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      invalidatesTags: (result, error, { id }) => [
        { type: 'Hospitals', id: 'LIST' },
        { type: 'Hospitals', id },
        { type: 'Stats', id: 'LIST' },
      ],
    }),

    deleteHospital: build.mutation<void, { id : string }>({
      query: ({ id }) => ({
        url: `hospital/${id}`,
        method: 'DELETE',
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      invalidatesTags: (result, error, { id }) => [
        { type: 'Hospitals', id },
        { type: 'Stats', id: 'LIST' },
        { type: 'Stats', id: 'LastSynced' },
      ],
    }),

    // Use xhr.ts instead
    uploadToHospital: build.mutation<NewRes, { id : string, body : FormData }>({
      query: ({ id, body }) => ({
        url: `hospital/${id}/files`,
        method: 'POST',
        body,
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      invalidatesTags: (result, error, { id }) => [
        { type: 'Hospitals', id },
      ],
    }),

    //----------
                                                                              //

    createRegion: build.mutation<NewRes, Region>({
      query: (body) => ({
        url: `regions`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, _) => [
        { type: 'Regions', id: 'LIST' },
      ],
    }),

    getRegions: build.query<Region[], void>({
      query: () => ({
        url: `regions`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
        ? [
          ...result.map(({ ID }) => ({ type: 'Regions', id: ID } as const)),
          { type: 'Regions', id: 'LIST' },
        ] :
        [
          { type: 'Regions', id: 'LIST' },
        ],
    }),

    updateRegion: build.mutation<NewRes, { id : string, body : Region }>({
      query: ({ id, body }) => ({
        url: `region/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Regions', id: 'LIST' },
        { type: 'Regions', id },
        { type: 'Hospitals' },
      ],
    }),


    //----------
                                                                            //

    getEdge: build.query<Edge, string>({
      query: (id) => {
        return {
          url: `edge/${id}`,
          method: 'GET',
        }
      },
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      providesTags: (result, error, id) => [
        { type: 'Edges', id }
      ],
    }),

    updateEdgeAlertStats: build.mutation<Edge, {
      ID: string,
      AlertStats: boolean,
    }>({
      query: ({ID, AlertStats}) => {
        return {
          url: `edge/${ID}/alertStats`,
          method: 'PATCH',
          body: {
            AlertStats,
          }
        }
      },
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      invalidatesTags: (result, error, {ID}) => [
        { type: 'Edges', id: ID }
      ],
    }),

    recalcEdgeMonthStats: build.mutation<void, {
      id: string,
      month: string,
    }>({
      query: ({id, month}) => {
        return {
          url: `edge/${id}/recalcEdgeMonthStats/${month}`,
          method: 'POST',
          body: {
          },
        }
      },
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      invalidatesTags: (result, error, {id, month}) => [
        { type: 'Edges', id: id },
        { type: 'Stats', id: month },
        { type: 'Stats', id: 'total' },
      ],
    }),

    getStatsCounts: build.query<StatCountType[], string>({
      query: (month) => ({
        url: `stats/counts?${qs.stringify({ month })}`,
        method: 'GET',
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      providesTags: (result, _, month) =>
        [
          { type: 'Stats', id: month },
        ],
    }),

    getStatsLastSynced: build.query<Record<string, GetStatsLastSyncedRes>, void>({
      query: () => ({
        // url: `stats/lastSynced`,
        url: `stats/lastSync`,
        method: 'GET',
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      providesTags: (result) =>
        [
          { type: 'Stats', id: 'LastSynced' },
        ],
    }),

    getStatsTotal: build.query<StatTotalType, void>({
      query: () => ({
        url: `stats/total`,
        method: 'GET',
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      providesTags: (result) =>
        [
          { type: 'Stats', id: 'total' },
        ],
    }),


    getOrphanBuckets: build.query<OrphanBucket[], void>({
      query: () => ({
        url: `stats/orphanBuckets`,
        method: 'GET',
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      providesTags: (result) =>
        [
          { type: 'Stats', id: 'orphanBuckets' },
        ],
    }),


    updateEdgeStat: build.mutation<string, string>({
      query: (edge) => ({
        url: `/stats/edgeStat/${edge}`,
        method: 'POST',
        body: {},
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      invalidatesTags: (result, error, edge) => [
        { type: 'Stats' },
        { type: 'Stats', id: edge },
        { type: 'Stats', id: 'LastSynced' },
        { type: 'Stats', id: `LastSynced-${edge}` },
      ],
    }),

    updateEdgeBodyParts: build.mutation<string, UpdateEdgeBodyPartsInput>({
      query: ({edgeId, month}) => ({
        url: `/stats/bodyparts/${edgeId}/${month}`,
        method: 'POST',
        body: {},
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      invalidatesTags: (result, error, {edgeId, month}) => [
        { type: 'Stats' },
        { type: 'Stats', id: edgeId },
        { type: 'Stats', id: 'LastSynced' },
        { type: 'Stats', id: `LastSynced-${edgeId}` },
      ],
    }),

    getEdgeStats: build.query<StatCountType[], string>({
      query: (query) => ({
        url: `/stats/edgeStat/${query}`,
        method: 'GET',
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      providesTags: (result, _, query) =>
        [
          { type: 'Stats', id: query },
        ],
    }),


    getStatsAlert: build.query<string, string>({
      query: (month) => ({
        url: `stats/alert/${month}`,
        method: 'GET',
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      providesTags: (result, _, month) =>
        [
          {type: "StatsAlert", id: month},
        ],
    }),

    deleteStatsAlert: build.mutation<string, string>({
      query: (month) => ({
        url: `stats/alert/${month}`,
        method: 'DELETE',
      }),
      // transformResponse: (response: { data: Post }, meta, arg) => response.data,
      invalidatesTags: (result, _, month) =>
        [
          {type: "StatsAlert", id: month},
        ],
    }),

    updateEdgeBusinessStatus: build.mutation<string, {id: string, status: string} >({
      query: ({id, status}: {id: string, status: string}) => ({
        url: `edge/${id}/businessStatus`,
        method: 'PATCH',
        body: {
          BusinessStatus: status,
        }
      }),
      invalidatesTags: (result, _, {id}) =>
        [
          {type: "Edges", id},
        ],
    }),

    updateEdgeBusinessRemark: build.mutation<string, {id: string, data: string} >({
      query: ({id, data}: {id: string, data: string}) => ({
        url: `edge/${id}/businessRemark`,
        method: 'PATCH',
        body: {
          BusinessRemark: data,
        }
      }),
      invalidatesTags: (result, _, {id}) =>
        [
          {type: "Edges", id},
        ],
    }),

  }),
})

export default api
