import {
  useState,
  useReducer,
  useEffect,
  useCallback,
  useMemo,
  ChangeEvent,
} from 'react'
import {
  useParams,
} from 'react-router-dom'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
// import Container from '@mui/material/Container'

import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { printDate } from 'utils'
import { useCheckQueryRes, useCheckRes, useDebounce } from 'utils/hooks'

import PrettyJson, { PrettyCodes } from 'Components/PrettyJson'

import StatsTotalLine from 'features/stats/StatsTotalLine'
import type { StatsLineType } from 'features/stats/StatsTotalLine'

// import Accordion from 'Components/Accordion'
// import ToggleButton, {
//   genReducer,
// } from 'Components/ToggleButton'

import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import { parseStats } from 'features/stats'

import dataApi, {
  StatCountType,
  // StatTotalType,
} from 'services/data'

import type {
  Edge
} from './types'

// const compareFn = (asc : boolean, select : (k : Hospital) => any) => (a : Hospital, b : Hospital) => {
//   if (select(a) < select(b)) {
//     return asc ? -1 : 1;
//   }
//   if (select(a) > select(b)) {
//     return asc ? 1 : -1;
//   }
//   return 0;
// }

const smallTypeProps = {
  fontSize: 'small',
  overflow: 'auto',
  // overflowWrap: 'break-word',
  maxWidth: '99vw',
}

interface StatsType {
  total : number,
  appName : string,
  labels : string[],
  data : StatsLineType[],
}

const TOTAL = 'Total studies'
const COMPLETE_DAYS = 'Days with Completed (median)'
const ALL_DAYS = 'Days with all (median)'

const MODALITIES = ['MR', 'PET', 'XA', 'CT']
function getAppMatched(d : any) {
  for (let i = 0; i < MODALITIES.length; i++) {
    const m = MODALITIES[i]
    const v = d.Parsed?.Matched?.[m]?.Average
    if (!!v) {
      return {
        app: `${m} daily avg`,
        count: parseInt(v),
      }
    }
  }
  return {
    app: '',
    count: 0,
  }
}

function calcTotal(input : StatCountType[] | undefined) : StatsType {
  let output : StatsType = {
    total: 0,
    appName: '',
    labels: [],
    data: [],
  }
  if (!input) return output

  const details = parseStats(input)
  console.log(details)

  const total : number[] = []
  const procDays : number[] = []
  const totalDays : number[] = []
  const appMatched : number[] = []
  let appName = ''
  for (let d of details) {
    const t = parseInt(d.Total || "0")
    output.total += t
    output.labels.push(d.Month)
    total.push(t)
    procDays.push(d.Days ? parseInt(d.Days.split(' ')[0]) : 0)
    totalDays.push(d.Days ? parseInt(d.Days.split(' ')[1]) : 0)
    const appMatchedRes = getAppMatched(d)
    if (appName === '' && appMatchedRes?.app !== '') {
      appName = appMatchedRes?.app
    }
    appMatched.push(appMatchedRes?.count)
  }
  output.appName = appName
  output.data = [
    {
      label: TOTAL,
      data: total,
    },
    {
      label: COMPLETE_DAYS,
      data: procDays,
      // yAxisID: 'y',
    },
    {
      label: ALL_DAYS,
      data: totalDays,
    },
    {
      label: appName,
      data: appMatched,
    },
  ]
  return output
}

function BusinessFormComp({
  e,
  id,
} : {
  id : string,
  e : Edge,
}) {
  const [updateEdgeBusinessStatus, updateEdgeBusinessStatusRes] = dataApi.useUpdateEdgeBusinessStatusMutation()
  useCheckRes(updateEdgeBusinessStatusRes, `Update ${e?.Name} business status`)
  const handleBusinessStatusChange = (e : SelectChangeEvent<string>) => {
    // console.log(e)
    updateEdgeBusinessStatus({ id: id || "", status: e.target.value })
  }

  const [updateEdgeBusinessRemark, updateEdgeBusinessRemarkRes] = dataApi.useUpdateEdgeBusinessRemarkMutation()
  useCheckRes(updateEdgeBusinessRemarkRes, `Update ${e?.Name} business remark`)
  const [businessRemark, setBusinessRemark] = useState(e.BusinessRemark || "")
  const handleBusinessRemarkChange = (e : ChangeEvent<HTMLInputElement>) => {
    // console.log(e)
    setBusinessRemark(e.target.value)
  }

  const cancelBusinessRemarkChange = useCallback(() => {
    if (!!e.BusinessRemark) {
      setBusinessRemark(e.BusinessRemark)
    } else {
      setBusinessRemark("")
    }
  }, [e.BusinessRemark])
  const submitBusinessRemarkChange = () => {
    updateEdgeBusinessRemark({ id: id || "", data: businessRemark })
  }
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <FormControl
        fullWidth={false}
        variant="outlined"
        required={false}
      >
        <InputLabel id="business-status-select-label">Business Status</InputLabel>
        <Select
          labelId="business-status-select-label"
          id="demo-simple-select"
          value={e.BusinessStatus}
          label="Business Status"
          onChange={handleBusinessStatusChange}
          autoWidth={true}
          disabled={false}
          error={false}
          inputProps={{ readOnly: false }}
        >
          <MenuItem value={"inService"}>
            inService
          </MenuItem>
          <MenuItem value={"deceased"}>
            Deceased
          </MenuItem>
        </Select>
      </FormControl>


      <FormControl
        fullWidth={false}
        variant="outlined"
        required={false}
        sx={{
          flexDirection: 'row',
          flex: 1,
        }}
      >
        {/*               <InputLabel id="business-remark-label">Business Remark</InputLabel> */}
        <TextField
          id="business-remark-label"
          value={businessRemark}
          placeholder="Business remark"
          onChange={handleBusinessRemarkChange}
          disabled={false}
          error={false}
          inputProps={{ readOnly: false }}
          multiline={true}
          sx={{
            flex: 1,
          }}
        >
        </TextField>
        <Button
          onClick={cancelBusinessRemarkChange}
        >
          Cancel
        </Button>
        <Button
          onClick={submitBusinessRemarkChange}
        >
          Submit
        </Button>
      </FormControl>
    </Box>
  )
}


export default function EdgeComp() {
  const { id } = useParams()

  const getEdgeRes = dataApi.useGetEdgeQuery(id || "")
  // console.log("getEdgeRes.isFetching: ", getEdgeRes.isFetching)
  useCheckQueryRes(getEdgeRes, `Get edge ${id}`)

  const e = getEdgeRes.data as Edge

  const getEdgeStatsRes = dataApi.useGetEdgeStatsQuery(id || "")
  useCheckQueryRes(getEdgeStatsRes, `Get edge stats ${id}`)

  const [updateEdgeAlertStats, updateEdgeAlertStatsRes] = dataApi.useUpdateEdgeAlertStatsMutation()
  useCheckRes(updateEdgeAlertStatsRes, `Update ${e?.Name} alert stats`)
  const handleAlertStatsChange = (e : ChangeEvent<HTMLInputElement>) => {
    console.log(e.currentTarget)
    updateEdgeAlertStats({ ID: id || "", AlertStats: Boolean(e.currentTarget.checked) })
  }

  const ip = useMemo(() => {
    let ret = ""
    try {
      ret = window.atob(e?.StaticIP || "")
    } catch {
      ret = e?.StaticIP || ""
    }
    return ret
  }, [e?.StaticIP])

  if (id === undefined) return null
  if (!e) return null

  const stats = calcTotal(getEdgeStatsRes.data)
  // console.log(stats)
  // const months = Object.keys(total.MonthCounts)

  if (getEdgeRes.isFetching) return (
    <div>
      Loading...
    </div>
  )

  return (
    <>
      <Box
        sx={{
          paddingTop: '0.5em',
        }}
      >
        <Box>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              backgroundImage: `linear-gradient(to top, rgba(255,0,0,0) 0, #bbb 100%)`,
              borderRadius: '5px',
              padding: '0 0.5em',
            }}
          >

            <Typography
              variant='subtitle1'
            >{e.Name}</Typography>

            <FormControlLabel
              control={
                <Switch
                  disabled={updateEdgeAlertStatsRes.isLoading}
                  checked={!!e.AlertStats}
                  onChange={handleAlertStatsChange}
                />}
              label="Alert Stats"
            />

          </Stack>

          <BusinessFormComp
            {...{
              e,
              id,
            }}
          />

          <StatsTotalLine
            key={'total'}
            id={'total'}
            labels={stats.labels}
            data={stats.data.filter(d => d.label === TOTAL)}
            title={`In total ${stats.total}`}
          />

          <StatsTotalLine
            key={stats.appName}
            id={stats.appName}
            labels={stats.labels}
            data={stats.data.filter(d => d.label === stats.appName)}
            title={`${stats.appName}`}
          />

          <StatsTotalLine
            key={'days'}
            id={'days'}
            labels={stats.labels}
            data={stats.data.filter(d => [COMPLETE_DAYS, ALL_DAYS].includes(d.label))}
            title={`Days`}
            scales={{
              // y: {
              //   type: 'linear',
              //   display: true,
              //   position: 'left',
              // },
              // y1: {
              //   type: 'linear',
              //   display: true,
              //   position: 'right',
              //   // grid line settings
              //   grid: {
              //     drawOnChartArea: false, // only want the grid lines for one axis to show up
              //   },
              // },
            }}
          />

          <Box>
            <Typography component="div" variant='subtitle2' sx={{ opacity: '0.7' }}>Created: {printDate(e?.CreatedAt)}</Typography>
            <Typography component="div" variant='subtitle2' sx={{ opacity: '0.7' }}>EdgeUpdatedAt: {printDate(e?.UpdatedAt)}</Typography>
            <Typography component="div" variant='subtitle2' sx={{ opacity: '0.7' }}>Uploaded: {printDate(e?.LastUploadTime)}, from {e.LastUploadIP !== '' ? e.LastUploadIP : "-"}</Typography>
            <Typography component="div" variant='subtitle2' sx={{ opacity: '0.7' }}>Downloaded: {e.LastDownloadIP}@{printDate(e?.LastDownloadTime)}</Typography>

            <Typography component="div" variant='subtitle2' sx={{ opacity: '0.7' }}>LastSyncedToInfluxAt: {printDate(e?.InfluxLastSync?.Valid ? e?.InfluxLastSync.Time : undefined)}</Typography>
            <Typography component="div" variant='subtitle2' sx={{ opacity: '0.7' }}>influxdb-sql-at: {printDate(e?.LastUpdateStatAt)}</Typography>
          </Box>

          <Box
            style={{
              marginTop: '0.5em',
              marginBottom: '0.5em',
            }}
          >
            <Typography>Network</Typography>
            <Typography variant='subtitle2'>IP: {ip}</Typography>
            <Typography variant='subtitle2'>Gateway: {e.Gateway}</Typography>
          </Box>

          <Typography>Nodes:</Typography>
          <PrettyJson input={window.atob(e.Nodes || "")} wrapArray={true} />
          <Typography>Routes:</Typography>
          <PrettyJson input={window.atob(e.Routes || "")} wrapArray={true} />
          <Typography>Apps:</Typography>
          <PrettyJson input={window.atob(e.AppsJson || "")} wrapArray={true} />

          <Typography>MR License:</Typography>
          <PrettyJson input={window.atob(e.MrLicense || "")} wrapArray={false} hideKeys={["LicenseKey"]} />
          <Typography>MR Config:</Typography>
          <PrettyCodes lang="yaml" input={window.atob(e.MrConfig || "")} />

          <Typography>PET Config:</Typography>
          <PrettyCodes lang="yaml" input={window.atob(e.PetConfig || "")} />
          <Typography>PET License:</Typography>
          <PrettyJson input={window.atob(e.PetLicense || "")} wrapArray={false} hideKeys={["LicenseKey"]} />

          <Typography>Version/ID</Typography>
          <Typography component="div">edge: {e.SubtleVersion}</Typography>
          <Typography component="div">edgeDocker: {e.SubtleDockerVersion}</Typography>
          <Typography component="div">teamviewer: {e.TeamviewerVersion}. ID: {e.TeamviewerId}</Typography>
          <Typography component="div">rustdeskID: {e.RustdeskId || "-"}</Typography>
          {/* {e.RustdeskPasswd} */}

          <Typography style={{ marginTop: '0.5em' }}>Hardware ID</Typography>
          <Box>
            <Typography style={smallTypeProps} component="div">CPU: {e.Cpu}</Typography>
            <Typography style={smallTypeProps} component="div">BIOS: {window.atob(e.Bios || "")}</Typography>
            <Typography style={smallTypeProps} component="div">Motherboard: {window.atob(e.Motherboard || "")}</Typography>
            <Typography style={smallTypeProps} component="div">GPU: {window.atob(e.Gpu || "") || "-"}</Typography>
            <Typography style={smallTypeProps} component="div">MAC: {e.Mac || "-"}</Typography>
          </Box>
        </Box>
      </Box>

    </>
  )
}
