
import { BE_URL } from 'config'

import { useSelectAuth } from 'features/auth/slice'
import { useSetProgress } from 'Components/Progress'
import { useSetSnack } from 'Components/Snackbar/slice'

import { api } from 'services/data'

export function useUploadToHospital() {

  const { token } = useSelectAuth()
  const setProgress = useSetProgress()
  const setSnack = useSetSnack()

  const onProgress = (e: ProgressEvent) => {
    if (!e.lengthComputable) {
      console.error("Progress incomputable!!!")
      return
    }
    const v = e.loaded
    const t = e.total
    setProgress(1.0 * 100 * v / t)
  }

  const onError = () => {
    setSnack('error', `Upload to hospital failed`)
  }

  return ({
    id,
    body,
  }: {
    id: string,
    body: FormData,
  }) => {

    const onSuccess = () => {
      setSnack('success', `Upload to hospital successfully`)
      api.util.invalidateTags([{ type: 'Hospitals', id },])
    }

    const req = new XMLHttpRequest();
    req.upload.addEventListener("progress", onProgress);
    req.upload.addEventListener("load", onSuccess);
    req.upload.addEventListener("error", onError);
    // req.upload.addEventListener("abort", transferCanceled);
    req.open('post', `${BE_URL}/hospital/${id}/files`);
    req.setRequestHeader('authorization', `Bearer ${token}`)
    req.send(body);
  }
}
