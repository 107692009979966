import { combineReducers } from 'redux'
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'

import logger from 'redux-logger'

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer, { slice as authSlice, initAuth } from 'features/auth/slice'
import snackReducer, { slice as snackSlice, setSnack } from 'Components/Snackbar/slice'
import { reducer as loadingReducer, slice as loadingSlice } from 'Components/Loading'
import { reducer as progressReducer, slice as progressSlice } from 'Components/Progress'

import authApi from '../services/auth'
import dataApi from '../services/data'

const rootReducer = combineReducers({
  auth: authReducer,
  snack: snackReducer,
  loading: loadingReducer,
  progress: progressReducer,
  [authApi.reducerPath]: authApi.reducer,
  [dataApi.reducerPath]: dataApi.reducer,
})
// Remember to blacklist any rtk-query apis
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  // Disable persistence for auth now, since we can't purge it when logging out
  // whitelist: [authSlice.name],
  whitelist: [],
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      // console.warn('We got a rejected action: ', JSON.stringify(action, null, 2))
      const msg = action.payload.error || action.payload.data
      if (!!msg) api.dispatch(setSnack({severity: 'error', content: msg}))
      if (action.payload.originalStatus === 403 || action.payload.status === 403) {
        api.dispatch(initAuth())
        window.location.replace("/login")
      }
    }
    return next(action)
  }

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(authApi.middleware)
      .concat(dataApi.middleware)
      .concat(rtkQueryErrorLogger)
    if (process.env.NODE_ENV === `development`) {
      middlewares.push(logger);
    }
    return middlewares
  }
});

export const persistor = persistStore(store)

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> =
  ThunkAction<
ReturnType,
             RootState,
             unknown,
             Action<string>
>;
