import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { RootState, AppThunk } from '../../app/store';

export interface AuthType {
  token : string;
  username : string;
  email : string;
}

const initialState : AuthType = {
  token: "",
  username: "",
  email: "",
}

// const AUTH_KEY = "auth"
// export function saveAuth(auth: AuthType) {
//   console.log("saveAuth: ", auth)
//   localStorage.setItem(AUTH_KEY, JSON.stringify(auth))
// }
// export function deleteAuth() {
//   console.log("deleteAuth")
//   localStorage.removeItem(AUTH_KEY)
// }
// export function loadAuth() : AuthType | null {
//   const res = localStorage.getItem(AUTH_KEY)
//   console.log("loadAuth: ", res)
//   if (res === null) return null
//   return JSON.parse(res)
// }

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action : PayloadAction<string>) => {
      state.token = action.payload;
    },
    setAuth: (state, action : PayloadAction<AuthType>) => {
      return action.payload
    },
    initAuth: (state, action : PayloadAction<void>) => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      // customEntityAdapter.removeAll(state);
    });
  },
});

const selectAuth = (state : RootState) => state.auth;
export function useSelectAuth() {
  return useAppSelector(selectAuth)
}

export const {
  // setToken,
  setAuth,
  initAuth,
} = slice.actions;
// export function useSetToken() {
//   const dispatch = useAppDispatch()
//   return (token: string)=>{
//     dispatch(setToken(token))
//   }
// }
export function useSetAuth() {
  const dispatch = useAppDispatch()
  return (auth : AuthType) => {
    dispatch(setAuth(auth))
  }
}
export function useInitAuth() {
  const dispatch = useAppDispatch()
  return () => {
    dispatch(initAuth())
  }
}

export default slice.reducer;
