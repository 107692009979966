
import {DateTime} from 'luxon'

export function humanFileSize(size : number) : string {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / Math.pow(1024, i)).toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

export function printDate(d : string | undefined) {
  // console.log(d)
  if (d === undefined) return "-"
  return DateTime.fromISO(d).setLocale('zh').toFormat("yyyy-LL-dd HH:mm:ss")
  // UTC+8
}
