import {
  useState,
  useEffect
} from 'react'

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HomeIcon from '@mui/icons-material/Home';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import TaskIcon from '@mui/icons-material/Task';
// import WarningIcon from '@mui/icons-material/Warning';

import { useNavigate } from 'react-router-dom'

import {
  useOidc2 as useOidc,
  useOidcUser2 as useOidcUser,
} from 'utils/hooks'

export default function Index() {
  // const auth = useSelectAuth()
  // const initAuth = useInitAuth()
  // const [login, setLogin] = useState(false)
  const { login, logout, isAuthenticated } = useOidc();

  const navigate = useNavigate()

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event : React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = (key : string) => async () => {
    if (key === 'logout') {
      await logout()
      // initAuth()
      // await persistor.purge()
    }
    if (key === 'admin') {
      navigate("/admin")
    }
    setAnchorElUser(null);
  };

  const { oidcUser } = useOidcUser();
  // , oidcUserLoadingState
  // console.log("oidcUser: ", oidcUserLoadingState)

  const [settings, setSettings] = useState(['profile', 'logout'])

  useEffect(() => {
    console.log(oidcUser)
    if (oidcUser?.email === "chaofeng@subtlemedical.cn") {
      setSettings(settings => {
        if (!settings.includes("admin")) {
          settings.unshift('admin')
        }
        return settings
      })
    }
  }, [oidcUser])

  return (
    <>
      {/* <AuthComp /> */}
      {/* <KeycloakComp open={login} setOpen={setLogin} /> */}
      <AppBar position="fixed">
        <Toolbar>
          <Box
            sx={{ flexGrow: 0 }}
          >
            <Tooltip title="Home" arrow>
              <Button
                color='inherit'
                onClick={() => navigate('/')}
                startIcon={<HomeIcon />}
              >
                Subtle OpsWeb
              </Button>
            </Tooltip>
          </Box>
          <Box sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
          }}>
            <Tooltip title="Statistics" arrow>
              <IconButton
                color='inherit'
                onClick={() => navigate('/stats')}
              >
                <AnalyticsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Statistics Last Sync" arrow>
              <IconButton
                color='inherit'
                onClick={() => navigate('/stats/lastSync')}
              >
                <HistoryToggleOffIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Consistency Check" arrow>
              <IconButton
                color='inherit'
                onClick={() => navigate('/check')}
              >
                <TaskIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {!isAuthenticated && <Button sx={{ color: 'white' }} onClick={() => login('/')}>
              Login
            </Button>}
            {isAuthenticated && <>
              <Button sx={{ color: 'white', textTransform: 'none', fontSize: 'small' }} onClick={handleOpenUserMenu}>
                {oidcUser?.preferred_username || "user"}
              </Button>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu("")}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu(setting)}>
                    <Typography textAlign="center" sx={{ textTransform: 'capitalize' }}>{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </>
            }
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}
