import {
  useState,
  // useReducer,
  // useRef,
  useCallback,
  useEffect,
  // useLayoutEffect,
  // useMemo,
  // ChangeEvent,
  // ChangeEvent,
} from 'react'

import Box from '@mui/material/Box'

import { Chart, ScaleOptionsByType, CartesianScaleTypeRegistry } from 'chart.js'
// import type { ChartData, ChartTypeRegistry, Point, BubbleDataPoint } from 'chart.js'

export interface StatsLineType {
  label : string,
  data : number[],
  yAxisID ?: string,
  stack ?: string,
  cubicInterpolationMode ?: string,
  tension ?: number,
}
// export type StatsLineType = ChartData<keyof ChartTypeRegistry, (number | Point | [number, number] | BubbleDataPoint | null)[], unknown>

interface ScalesType { [key : string] : Partial<ScaleOptionsByType<keyof CartesianScaleTypeRegistry>> }

const defaultScales : ScalesType = {
  x: {
    stacked: true,
    beginAtZero: true,
    // ticks: {
    //   autoSkip: false
    // },
  },
  y: {
    stacked: true
  },
}

export default function StatsTotalLine({
  id,
  labels,
  data,
  title,
  scales,
} : {
  id ?: string,
  labels : string[],
  //  data : StatTotalType | undefined,
  data : StatsLineType[] | undefined,
  title : string,
  scales ?: ScalesType,
}) {

  if (!id) id = 'statsTotal'
  if (!scales) scales = defaultScales

  const [countsChart, setCountsChart] = useState(null as null | Chart)
  const [canvas, setCanvas] = useState(null as null | HTMLCanvasElement)
  // const [chartMounted, setChartMounted] = useState(false)
  const ref = useCallback((node : HTMLCanvasElement | null) => {
    if (node !== null) {
      // setChartMounted(true)
      setCanvas(node)
    }
  }, [])

  // console.log(`id: ${id}`)

  useEffect(() => {
    // console.log(`before id: ${id}, canvas: ${canvas}, countsChart: ${countsChart}`)
    if (!canvas || countsChart !== null) return
    // const ele = window.document.getElementById(id!)! as HTMLCanvasElement
    const ele = canvas
    const chart = new Chart(
      ele,
      {
        type: 'line',
        data: {
          labels: [],
          datasets: []
        },
        options: {
          indexAxis: 'x',
          maintainAspectRatio: false,
          hover: {
            mode: 'nearest',
            intersect: true,
            axis: 'xy',
            includeInvisible: false
          },
          events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
          scales: scales,
        }
      }
    )
    setCountsChart(chart)
    // console.log(`after id: ${id}, canvas: ${canvas}, chart: ${chart}`)
    return () => {
      if (countsChart === null) return
      (countsChart as Chart).destroy()
      setCountsChart(null)
    }
  }, [canvas, countsChart, scales])

  useEffect(() => {
    // console.log(`Second effect: ${data}, countsChart: ${countsChart}`)
    if (!data || !countsChart) return

    countsChart.data = {
      labels,
      datasets: data.map(d => Object.assign({}, d, {
        // cubicInterpolationMode: 'monotone',
        tension: 0.4,
      })),
    }
    countsChart.options.plugins = {
      // legend: {
      //   position: 'right',
      // },
      title: {
        display: true,
        text: title,
      }
    }

    // if (countsChart?.options?.plugins?.title?.text) {
    //   countsChart.options.plugins.title.text = `Counts in ${month}`
    // }
    countsChart.update()
  }, [data, countsChart, title, labels])

  return (
    <Box>
      <canvas id={id} ref={ref} role='img' style={{ width: "90vw", height: `200px` }}></canvas>
    </Box>
  )
}
