
// import {
//   useState,
// } from 'react'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import StarBorder from '@mui/icons-material/StarBorder';

import { useCheckQueryRes } from 'utils/hooks'

import dataApi from 'services/data'

export default function Check() {
  const getOrphanBuckets = dataApi.useGetOrphanBucketsQuery()
  useCheckQueryRes(getOrphanBuckets, 'Get orphan buckets')

  return (
    <>

      <List
        dense={true}
        subheader={
          <ListSubheader component="div">
            Orphan Buckets (in influxdb but not in OpsWeb)
          </ListSubheader>
        }
      >

        {getOrphanBuckets?.data && getOrphanBuckets.data.map(b => (

          <ListItem
            key={b.ID}
          >
            <ListItemButton>

              <ListItemAvatar>
                <Avatar>
                  {b.Name[0]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={b.Name}
                secondary={<span>Updated: {b.UpdatedAt}</span>}
              />
            </ListItemButton>
          </ListItem>

        ))}
      </List>

    </>
  )
}
